<template>
  <div class="moderator-user">
    <router-view></router-view>
  </div>
</template>
<script>
// import MainHeader from "@/components/moderator/HeaderTable";
export default {
  // components: MainHeader,
};
</script>